

import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
    const [merchantId, setMerchantId] = useState(null);
    const [catalog, setCatalog] = useState([]);
    const [oauthCompleted, setOauthCompleted] = useState(false);
    const [phoneSubmitted, setPhoneSubmitted] = useState(false);
    const [vapiFileCreated, setVapiFileCreated] = useState(false);
    const [assistantCreated, setAssistantCreated] = useState(false);
    const [locationData, setLocationData] = useState([]); // State for storing location data
    const [businessName, setBusinessName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState({
        addressLine1: '',
        locality: '',
        district: '',
        postalCode: ''
    });
    const [openHours, setOpenHours] = useState([]);
    const [selectedLocationId, setSelectedLocationId] = useState(null); // Track selected locations

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        let merchantIdFromUrl = query.get('merchantId');

        if (merchantIdFromUrl && merchantIdFromUrl.includes('#')) {
            merchantIdFromUrl = merchantIdFromUrl.split('#')[0];
        }

        if (merchantIdFromUrl) {
            setMerchantId(merchantIdFromUrl);
            setOauthCompleted(true);
            fetchLocations(merchantIdFromUrl); // Fetch locations after OAuth is completed
        }
    }, []);

    useEffect(() => {
        if (phone) {
            submitPhone()
        }
    }, [phone])

    const fetchCatalog = async () => {
        if (!merchantId) {
            alert('Merchant ID is missing.');
            return;
        }

        console.log('Syncing and fetching catalog for Merchant ID:', merchantId);

        try {
            // Step 1: Trigger the sync process
            const syncResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/catalog/sync-catalog`, { merchantId });
            if (syncResponse.status !== 200 || !syncResponse.data.success) {
                console.error('Catalog sync failed:', syncResponse.data);
                alert(syncResponse.data.error || 'Failed to sync catalog.');
                return;
            }

            // Step 2: Fetch the catalog data after sync
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/catalog/get-catalog?merchantId=${merchantId}`);
            console.log('Catalog fetch response:', response.data);

            if (response.status === 200 && Array.isArray(response.data)) {
                setCatalog(response.data);
                // Step 3: Automatically create the VAPI file
                await createVapiFile();
            } else {
                console.error('Unexpected response:', response);
                alert('Failed to fetch catalog.');
            }
        } catch (error) {
            console.error('Error during catalog sync/fetch:', error);
            alert('Failed to sync and fetch catalog.');
        }
    };

    const createVapiFile = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/vapi/upload-knowledge-base`, { merchantId });
            if (response.status === 200 && response.data.success) {
                setVapiFileCreated(true);
                // Automatically proceed to creating the assistant
                await createAssistant();
            } else {
                alert('Failed to create VAPI file.');
            }
        } catch (error) {
            console.error('Error creating VAPI file:', error);
            alert('Failed to create VAPI file.');
        }
    };

    const createAssistant = async () => {
        try {
            const assistantConfig = {
                merchantId,
                phone,
            };

            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/vapi/create-assistant`, assistantConfig);
            if (response.status === 200 && response.data.success) {
                setAssistantCreated(true);
            } else {
                alert('Failed to create assistant.');
            }
        } catch (error) {
            console.error('Error creating assistant:', error);
            alert('Failed to create assistant.');
        }
    };

    const fetchLocations = async (merchantId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/locations/get-locations?merchantId=${merchantId}`);
            if (response.data.success) {
                const locations = response.data.locationData;
                setLocationData(locations);

                if (locations.length === 1) {
                    // If only one location, auto-select it
                    const location = locations[0];
                    handleLocationSelect(location);
                }
            }
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    const handleLocationSelect = async (location) => {
        setBusinessName(location.business_name || 'Unknown Business');
        setPhone(location.phone_number || '');
        setAddress({
            addressLine1: location.address?.address_line_1 || '',
            locality: location.address?.locality || '',
            district: location.address?.administrative_district_level_1 || '',
            postalCode: location.address?.postal_code || '',
        });
        setOpenHours(location.business_hours?.periods || []);
        setSelectedLocationId(location.id);

        try {
            console.log("UPDATING LOCATION TO HERE:", location)
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/locations/set-location`, { location });
            if (!(response.status === 200 && response.data.success)) {
                alert('Failed to save location Data.');
            }
        } catch (error) {
            console.error('Error saving location Data:', error);
            alert('Error saving location Data.');
        }

    };

    const submitPhone = async () => {
        if (!phone) {
            alert('Please enter a valid phone number.');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/set-phone`, { merchantId, phone });
            if (response.status === 200 && response.data.success) {
                setPhoneSubmitted(true);
                fetchCatalog();
            } else {
                alert('Failed to save phone number.');
            }
        } catch (error) {
            console.error('Error saving phone number:', error);
            alert('Error saving phone number.');
        }
    };

    const formatTimeToAmPm = (timeStr) => {
        const [hour, minute] = timeStr.split(':');
        let hours = parseInt(hour);
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 24-hour to 12-hour format
        return `${hours}:${minute} ${ampm}`;
    };

    return (
        <div className='mainContainer'>
            <nav>
                <a href="">OrderRing</a>
                {!oauthCompleted && (
                    <a href="">sign in</a>
                )}
            </nav>

            {!oauthCompleted && (
                <div className="card signIn">
                    <h2>Sign in</h2>
                    <p>Get access to your custom phone AI</p>
                    <a href={`${process.env.REACT_APP_SERVER_URL}/oauth/authorize`}>
                        <button className='oauthButton'>
                            <img src="/square.png" width={30} alt="square logo" />
                            <span>Sign in with Square</span>
                        </button>
                    </a>
                    <div className="bottomLinks">
                        <a href="">Need help?</a>
                        <a href="">privacy</a>
                    </div>
                </div>
            )}

            {oauthCompleted && locationData.length > 1 && !selectedLocationId && (
                <div className='card'>
                    <h2>Select a Location</h2>
                    <ul>
                        {locationData.map((location) => (
                            <li key={location.id} onClick={() => handleLocationSelect(location)}>
                                <h3>{location.name}</h3>
                                <p>{location.address.address_line_1}, {location.address.locality}, {location.address.administrative_district_level_1} {location.address.postal_code}</p>
                                <p>Phone: {location.phone_number}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {oauthCompleted && phoneSubmitted && assistantCreated && selectedLocationId && (
                <div className='card'>
                {/* Display the business information */}
                <h1>{businessName}</h1>
                <h2>Phone: {phone}</h2>
                <p>
                    Location: {address.addressLine1}, {address.locality}, {address.district}, {address.postalCode}
                </p>
                <section>
                    <div className="product">
                        <h3>Starter Plan</h3>
                        <h5>$20.00 / month</h5>
                    </div>
                    <form action={`${process.env.REACT_APP_SERVER_URL}/stripe/create-checkout-session`} method="POST">
                        {/* Add a hidden field with the lookup_key of your Price */}
                        <input type="hidden" name="lookup_key" value="test" />
                        <input type="hidden" name="customerId" value={merchantId} />
                        <button type="submit">
                        Checkout
                        </button>
                    </form>
                    </section>
                <div className="business-hours">
                    <h3>Open Hours:</h3>
                    <ul>
                        {openHours.map((period, index) => (
                            <li key={index}>
                                {period.day_of_week}: {formatTimeToAmPm(period.start_local_time)} - {formatTimeToAmPm(period.end_local_time)}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            )}

            {!phoneSubmitted && oauthCompleted && selectedLocationId && (
                <div className='card'>
                    <h2>Business Phone Number:</h2>
                    <div className="phoneInput">
                        <input
                            type="tel"
                            placeholder="Enter phone number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <button onClick={submitPhone}>Submit</button>
                    </div>
                </div>
            )}
            {vapiFileCreated && !assistantCreated && (
                <div>
                    <p>VAPI file created! Setting up your assistant...</p>
                </div>
            )}
    
            {catalog.length > 0 && (
                <div className='menu'>
                    <h3>Menu</h3>
                    <div className="menuItems">
                        {catalog.map((item) => (
                            <div key={item.variation_id} style={{backgroundImage: `url(${item.image_url})`}} className="menuItem">
                                <strong>{item.item_name} ({item.variation_name})</strong><br />
                                {item.item_description}<br />
                                Price: {item.variation_price / 100} {item.currency}
                            </div>
                        ))}
                    </div>
                    
                </div>
            )}
        </div>
    );
}

export default App;
